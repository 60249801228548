.framed-container {
  margin: 30px auto;
  border-radius: 15px;
  padding: 40px;
  box-shadow:
    rgba(0, 0, 0, 0.02) 0 1px 3px 0,
    rgba(27, 31, 35, 0.15) 0 0 0 1px;
  background-color: white;
  max-width: 100% !important;
}
